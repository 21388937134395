<template lang="">
  <!-- 初始状态 -->
  <div class="fun-area-wrap fun-area-wrap-border" v-if="checkAppState() == 'start'">
    <div class=" grid-content bg-purple-light" @click='changeClickOrDrag'>
      <el-upload class="upload-demo upload-area upload-div dotteBox" drag ref="upload" action="" :auto-upload="false"
        multiple :show-file-list="false" :on-change="(file,fileList)=>clickUpload(file,fileList,'click')"
        :file-list="fileList" accept="application/pdf">
        <div class="flexBox" :key="index">
          <div style="display:flex;flex-direction: column;align-items: center;">
            <img class="ptw" src="../assets/merge/merge.svg" alt="">
            <p style="font-size:32px;font-weight:bold;margin:40px 0 8px 0px;line-height: 44px;">
              {{$t(`message.FunOperat.mergePDF.title`)}}
            </p>
            <p
              style="font-family: 'Inter';font-style: normal;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.FunOperat.mergePDF.p`)}}</p>
            <p
              style="font-family: 'Inter';font-style: normal;margin:64px 0 8px 0;font-weight: 400;font-size: 16px;line-height: 22px;color: rgba(29, 29, 31, 0.7);">
              {{$t(`message.selectFile[3]`)}}
            </p>
            <div class="selectButton cursor">{{$t(`message.selectFile[1]`)}}</div>
            <div style="margin-top:32px">
              <svg style="margin-top:-3px ;" width="13" height="15" viewBox="0 0 13 15" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M6.65811 0.0256583C6.55548 -0.00855278 6.44452 -0.00855278 6.34189 0.0256583L0.341886 2.02566C0.137715 2.09372 0 2.28478 0 2.5V9.5C0 9.60554 0.0333988 9.70838 0.0954135 9.79379C0.286104 10.0564 0.470108 10.3171 0.651201 10.5737L0.651239 10.5737C1.27002 11.4504 1.85483 12.2789 2.5563 12.9713C3.49179 13.8947 4.64103 14.5911 6.38941 14.9876C6.46157 15.004 6.53646 15.0041 6.60868 14.988C9.8687 14.2621 11.3239 12.288 12.9222 9.7678C12.973 9.68771 13 9.59483 13 9.5V2.5C13 2.28478 12.8623 2.09372 12.6581 2.02566L6.65811 0.0256583ZM1 9.33811V2.86038L6.5 1.02705L12 2.86038V9.35452C10.479 11.7411 9.22029 13.3468 6.50088 13.9869C4.99483 13.6278 4.04041 13.0311 3.25878 12.2596C2.62646 11.6355 2.11047 10.9051 1.50375 10.0462L1.50369 10.0461C1.3434 9.81922 1.17677 9.58335 1 9.33811ZM9.89468 5.80696C10.0642 5.58899 10.0249 5.27485 9.80697 5.10532C9.589 4.93578 9.27486 4.97505 9.10532 5.19302L5.95305 9.24594L4.35355 7.64644C4.15829 7.45118 3.84171 7.45118 3.64645 7.64644C3.45118 7.8417 3.45118 8.15828 3.64645 8.35355L5.64645 10.3535C5.74786 10.455 5.88793 10.5079 6.03107 10.499C6.17421 10.4901 6.30663 10.4202 6.39468 10.307L9.89468 5.80696Z"
                  fill="#BBBBBC" />
              </svg>
              <span
                style="color: rgba(29, 29, 31, 0.3);margin-left:8px;font-family: 'Inter';font-style: normal;font-size: 16px;color: rgba(29, 29, 31, 0.3);">{{$t(`message.selectText[1]`)}}</span>
            </div>
          </div>
        </div>
      </el-upload>
    </div>
  </div>
  <!-- 有文件的时候 -->

  <!-- <div id="drop-area" @drop="dropEvent" @dragenter.prevent @dragover.prevent class="fun-area-wrap border-left"
    style="width: 834px;" v-if="checkAppState() == 'operate'"> -->
  <!-- <div class="grid-content bg-white"> -->
  <div id="drop-area" @drop="dropEvent" @dragenter.prevent @dragover.prevent class="fun-area-wrap border-left"
    style="width: 834px;" v-if="checkAppState() == 'operate'">
    <div class="grid-content" style="height: 507px;">
      <div id="pre-pdf-area" class="pdf-container">
        <canvas v-for="page in fileList.length" :id="'canvas' + page" :key="page" class="pre-pdf-img"></canvas>
      </div>
      <div class="pre-icon-wrap">
        <draggable v-model="fileList" @start="onStart" @end="onEnd" item-key="id">
          <template #item="{element , index}">
            <div class="item-cell">
              <div class="pre-img-cell-wrap">
                <div class="pre-num">{{index+1}}</div>
                <div class="pre-bg">
                  <!-- <img class="pre-img-cell pre-img mx-auto" v-if="!element.preImg"
                    src="https://pdnob.passfab.com/images/pdnob1-4/icon-off-pdf2.svg" alt="" /> -->
                  <img class="pre-img-cell pre-img mx-auto" src="../assets/merge/loading.gif" alt=""
                    style="position: absolute;top: 8px;left: 7px;" v-if="!element.preImg" />
                  <!-- <img src="../assets/merge/filebg.svg" class="pre-img-bg" alt=""> -->
                  <div class='pre-img-cell-conner d-flex justify-content-center align-items-center'
                    style="height: 91.5%;">
                    <img v-if="element.preImg && fileList.length > 1"
                      :title="$t(`message.FunOperat.mergePDF.add.text[4]`)" class="pre-img-cell" :src="element.preImg"
                      alt="" />
                    <img v-else-if="element.preImg" class="pre-img-cell" :src="element.preImg" alt="" />
                    <!-- <img src="../assets/download/toptop.png" class="pre-img-bg" v-if="element.preImg" alt=""> -->
                  </div>
                </div>

              </div>
              <div class="file-name-wrap" style="cursor:default" v-if="element.name.length <= 17">
                <p>{{ element.name}}</p>
                <!-- <p>{{ element.name.substring(0, element.name.lastIndexOf(".")) }}</p>
                <span>{{element.name.substring(element.name.lastIndexOf(".")) }}</span> -->
              </div>
              <div class="file-name-wrap" style="cursor:default" :title="element.name" v-else>
                <p>
                  {{
                  element.name.substring(0,7)}}...{{element.name.substring(element.name.length - 9)}}
                </p>
                <!-- <p>{{
                  element.name.substring(0,7)}}...{{element.name.substring(element.name.lastIndexOf(".")-6,element.name.lastIndexOf("."))}}
                </p>
                <span>{{element.name.substring(element.name.lastIndexOf(".")) }}</span> -->
              </div>
              <!-- <div class="flex-justify-between d-flex hover-operation-area" v-if="!element.fail"> -->
              <div class="flex-justify-between d-flex hover-operation-area" v-if="!element.fail && element.preImg">
                <div class="">
                  <span class="turn-right text-white" @click="turnRight(index)">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M5 12C5 8.13689 8.14209 5 12.0245 5C15.3781 5 18.1806 7.34152 18.8806 10.4689L17.8325 10.5953C17.4746 10.6384 17.3196 11.0717 17.5688 11.3321L20.0566 13.9313C20.2583 14.142 20.6057 14.1001 20.7515 13.8475L22.5501 10.7315C22.7304 10.4193 22.4768 10.0353 22.1189 10.0784L20.874 10.2285C20.0482 6.10394 16.3987 3 12.0245 3C7.04328 3 3 7.02656 3 12C3 16.9734 7.04328 21 12.0245 21C14.445 21 16.6452 20.0483 18.2654 18.501C18.6648 18.1195 18.6794 17.4865 18.2979 17.0871C17.9165 16.6877 17.2835 16.6732 16.8841 17.0546C15.6216 18.2602 13.911 19 12.0245 19C8.14209 19 5 15.8631 5 12Z"
                        fill="white" />
                    </svg>
                  </span>
                  <span @click="removeSingleFile(index)" class="turn-left text-white">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7 5C7 3.34315 8.34315 2 10 2H14C15.6569 2 17 3.34315 17 5V6H20H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H20V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4H7V5ZM9 6H15V5C15 4.44772 14.5523 4 14 4H10C9.44772 4 9 4.44772 9 5V6ZM6 8V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V8H17H7H6ZM10 11C10.5523 11 11 11.4477 11 12V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V12C9 11.4477 9.44772 11 10 11ZM15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V12Z"
                        fill="white" />
                    </svg>
                  </span>
                </div>
              </div>
              <!-- <div v-else class="flex-justify-between d-flex hover-area"> -->
              <div v-else-if="element.fail == true" class="flex-justify-between d-flex hover-area">
                <div>
                  <span @click="removeSingleFile(index)" class="turn-left text-white" style="margin-left: 5px;">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                        d="M7 5C7 3.34315 8.34315 2 10 2H14C15.6569 2 17 3.34315 17 5V6H20H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H20V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4H7V5ZM9 6H15V5C15 4.44772 14.5523 4 14 4H10C9.44772 4 9 4.44772 9 5V6ZM6 8V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V8H17H7H6ZM10 11C10.5523 11 11 11.4477 11 12V16C11 16.5523 10.5523 17 10 17C9.44772 17 9 16.5523 9 16V12C9 11.4477 9.44772 11 10 11ZM15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12V16C13 16.5523 13.4477 17 14 17C14.5523 17 15 16.5523 15 16V12Z"
                        fill="white" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>

    </div>
    <hr style="background-color: grey; margin: 0" />
    <div style="display: flex;justify-content: space-between;height: 40px;">
      <div class="add-filelength">
        <span class="file-style">{{$t(`message.FunOperat.mergePDF.add.text[0]`)}}</span>
        <span class="img-length-wrap" :imglength="fileList.length">
          <span class="img-current-length">{{ fileList.length }}</span>
          <span class="img-current-length">/5</span>
        </span>
      </div>
      <div v-if="fileList.length < 5" class="uploadBox">
        <el-upload action="" :auto-upload="false" :show-file-list="false" multiple :on-change="clickUpload"
          :file-list="fileList" ref="my-upload" accept="application/pdf" :disabled="fileList.length == 5">
          <button id="add-img-btn" :disabled="fileList.length == 5">
            <img src="../assets/merge/add.svg" alt="" />
            <span>{{$t(`message.FunOperat.mergePDF.add.text[1]`)}}</span>
          </button>
        </el-upload>
      </div>
      <div v-else class="uploadBox">
        <button id="add-img-btn1" :disabled="fileList.length == 5">
          <img src="../assets/merge/grey-add.svg" alt="" />
          <span>{{$t(`message.FunOperat.mergePDF.add.text[1]`)}}</span>
          <span class="not-more-four">{{$t(`message.FunOperat.mergePDF.add.text[3]`)}}</span>
        </button>
      </div>
    </div>
  </div>
  <div style="height: 548px;background: #EAEAEA;width: 1px;"></div>
  <!-- 添加pdf文件 -->
  <div style="width:389px" class="action-area-wrap fun-area-wrap border-right" v-if="checkAppState() == 'operate'">
    <div class="add-img-wrap">
      <div>
        <h3 style='text-align: center;'>{{$t(`message.FunOperat.mergePDF.title`)}}</h3>
      </div>
      <p class="prompt-tit" v-if="fileList.length > 1">
        {{$t(`message.FunOperat.mergePDF.add.text[2]`)}}
      </p>
    </div>

    <button id="btn-convert1" v-if="!canClick" class="btn-combine">
      {{$t(`message.UploadFun.title`)}}
    </button>
    <button id="btn-convert1" v-else-if="fileList.length == 1" class="btn-combine">
      {{$t(`message.FunOperat.mergePDF.Operat`)}}
    </button>
    <button id="btn-convert" @click="clickConvert()" v-else>
      {{$t(`message.FunOperat.mergePDF.Operat`)}}
    </button>
    <div class='hover-span' v-if="fileList.length == 1">{{$t(`message.FunOperat.mergePDF.add.text[5]`)}}</div>
  </div>
  <el-col class="fun-area-wrap fun-area-wrap-border" :span="24" v-if="checkAppState() == 'converting'">
    <converting />
  </el-col>
  <failUpload class="fun-area-wrap fun-area-wrap-border" v-if="checkAppState() == 'failUpload'" />
  <failConvert class="fun-area-wrap fun-area-wrap-border" v-if="checkAppState() == 'failConvert'" />
  <!-- <failUpload class="fun-area-wrap fun-area-wrap-border" /> -->
  <download class="fun-area-wrap fun-area-wrap-border" v-model:currentFun.sync="currentFun"
    v-model:downflag.sync="downInof.downflag" v-if="checkAppState() == 'converted'" />
</template>

<script>
  // import pdf from "@/components/pdf/pdf";
  import { changeType, checkAppState } from "../components/convertFun/convert";
  import converting from "../components/common/converting.vue";
  import draggable from "vuedraggable";
  import download from "../components/Download.vue";
  import PDF from "pdfjs-dist";
  import failConvert from "../components/failConvert.vue";
  import failUpload from "../components/failUpload.vue";
  import { openPop, removeSingleFile } from "../api/common";
  import "../components/convertFun/convert.scss";

  export default {
    data() {
      return {
        fileList: [],
        fileType: "",
        fileSize: 0,
        filelen: 0,
        failArray: [],
        uploadSusFlag: false,
        warningFlag: false,
        currentFun: "Merge PDF",
        uploadState: "",
        fileLimited: 1,
        drag: false,//拖拽区
        downInof: {
          downUrl: "",
          downName: "",
          downFileType: "pdf",
          downflag: false,
        },
        btnloading: false,
        mergeUrl: "",
        pdfImgList: [],
        preImgSrc: "https://pdnob.passfab.com/images/pdnob1-4/icon-off-pdf2.svg",
        pages: 0,
        canvasList: [],
        splitPage: [],//上传旋转数组
        occupancyFlag: true,
        canClick: false,
        clickCovertFlag: false,
        addFileNum: 0,
        getFileFromState: false,
        clickUploadFlag: false,
        badFile: [], //损坏文件
        nowTime: null,
        pageName: "Merge_PDF",
        failJPG: 0,//破损文件
        totalSize: 0,//总文件大小
        taskId: '',//任务id
        operation: ''
      };
    },
    components: {
      // pdf,
      converting,
      draggable,
      download,
      failUpload,
      failConvert
    },
    methods: {
      openPop,
      removeSingleFile,
      changeType,
      checkAppState,
      changeClickOrDrag() {
        this.clickUploadFlag = true
      },
      beforeUpload(file, list) {
        console.log("before:", file, list);
      },
      dropEvent(e) {
        console.log("drope:", e);
        console.log("e.dataTransfer.files:", e.dataTransfer.files);
        e.stopPropagation()
        e.preventDefault()
        let _this = this
        e.dataTransfer.files.forEach((el) => {
          el.raw = el
          let obj = {}
          for (let i in el) {
            obj[i] = el[i]
          }
          _this.clickUpload(obj)
        })
      },
      //检查类型
      checkFile(fileMsg, ComefileList) {
        const _this = this;
        return new Promise((res, rej) => {
          _this.fileType = fileMsg.name.toLowerCase().substr(fileMsg.name.lastIndexOf(".") + 1);
          //console.log("this.fileSize:", _this.fileSize, fileMsg.size);
          if (_this.fileType.toLowerCase() != "pdf") {
            _this.openPop(_this, 2);
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "format_error",
            });
            this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            rej();
            return
          } else if (fileMsg.size > 30 * 1024 * 1024) {
            _this.openPop(_this, 3);
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "oversized",
            });
            this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            if (_this.operation == 'Windows') {
              _this.$store.commit('changeIsShowPur', true)
            }
            rej();
            return
          } else if (_this.fileSize + fileMsg.size > 100 * 1024 * 1024) {
            _this.$message({
              showClose: true,
              duration: 8000,
              type: "error",
              message: _this.$t(`message.UploadFun.message[0]`),
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "oversized",
            });
            this.$gtag.event("upload_file", {
              event_category: _this.pageName,
              event_label: "fail",
            });
            _this.$gtag.event("upload_fail", {
              event_category: _this.pageName,
              event_label: "0.1",
            });
            if (_this.operation == 'Windows') {
              _this.$store.commit('changeIsShowPur', true)
            }

            rej();
            return
          }
          _this.fileSize += fileMsg.size
          _this.fileList.push(fileMsg)
          //console.log("_this.fileList:", _this.fileList);
          res();
        });
      },
      //上传
      clickUpload(fileMsg, ComefileList, name) {
        const _this = this;
        // ComefileList.foreach((item, index) => {
        //   _this.totalSize + item.size
        // })
        // if (_this.totalSize > 100 * 1024 * 1024) {
        //   this.$store.commit('changeIsShowPur', true)
        //   return
        // }
        this.filelen = this.filelen + 1
        console.log("fileList:", fileMsg);
        //console.log(ComefileList);
        this.$gtag.event("upload", {
          event_category: this.pageName,
          event_label: "null",
        });
        if (this.checkAppState() == "operate") {
          _this.$gtag.event("add_files", {
            event_category: _this.pageName,
            event_label: "null",
          });
        }
        this.btnloading = true
        // //console.log("fileMsg:", fileMsg);
        // this.addFileNum++;
        if (this.fileList.length >= 5) {
          this.filelen--
          if (this.operation == 'Windows') {
            this.$store.commit('changeIsShowPur', true)
          }
          return
        }
        // _this.fileList.push(fileMsg);
        //console.log("_this.fileList:", _this.fileList.length);
        let length = _this.fileList.length
        this.checkFile(fileMsg, ComefileList).then(function name() {
          //console.log("uploadToServerProgress-------:", length);
          _this.canClick = false
          _this.uploadToServerProgress(length);
        }).catch((e) => {
          _this.filelen--
          if (_this.filelen == 0) {
            _this.btnloading = false
          }
        })


      },
      //上传文件拿到链接
      uploadToServerProgress(index) {
        // let gaStartime = new Date().getTime();

        this.uploadState = "2";
        const _this = this;

        //console.log("this.fileList:", this.fileList);
        //console.log("num:", index);

        const event = event || window.event;
        const file = this.fileList[index].raw ? this.fileList[index].raw : this.fileList[index];
        this.fileList[index]["document"] = file;
        this.$store.commit("changeAppState", "operate");
        const uploadFile = this.fileList[index].document;
        //console.log("this.fileList[index]:", this.fileList[index]);
        this.reTryUpload(3, "/app/uploadFile", uploadFile, index, _this);
      },
      clickConvert() {
        let fail = this.fileList.filter((item) => item.fail)
        let _this = this
        //console.log("fail:", fail);
        if (fail.length > 0) {
          _this.$message({
            showClose: true,
            duration: 8000,
            type: "error",
            message: _this.$t(`message.UploadFun.message[5]`),
          })
          _this.$gtag.event("process_fail", {
            event_category: _this.pageName,
            event_label: "file_error",
          });
          _this.$gtag.event("process_file", {
            event_category: _this.pageName,
            event_label: "fail",
          });
          _this.$gtag.event("process_fail", {
            event_category: _this.pageName,
            event_label: "0.1",
          });
          return
        } else {
          this.clickCovertFlag = true;
          this.$store.commit("changeAppState", "converting");
        }
      },
      //合并
      convertFun() {
        const _this = this;
        this.$store.commit("changeAppState", "converting");
        this.nowTime = new Date().getTime()
        let formData = new FormData();
        formData.append("token", _this.$store.state.pdfToken);
        for (let i = 0; i < _this.fileList.length; i++) {
          formData.append("file_url[]", _this.fileList[i].fileUrl);
          let newArray = (new Array(_this.fileList[i].pageNums)).fill(_this.fileList[i].rotate)
          _this.splitPage = _this.splitPage.concat(newArray)
          //console.log("_this.fileList[i]:", _this.fileList[i]);
        }
        let reMergeTry = ((times, api, url, num, _this) => {
          _this.$gtag.event("click_combine", {
            event_category: _this.pageName,
            event_label: _this.fileList.length
          });
          //console.log("reMergeTry:", times, api, url, num, _this);
          return new Promise((resolve, reject) => {
            function reMergeTry(times, api, url, num, _this) {
              // //console.log(`第${times}次重试`);
              _this.$http
                .post(api, url).then((res) => {
                  //console.log("res:", res);
                  //console.log(res.code == 200);
                  _this.$http
                    .ws(_this.$store.state.pdfToken, res.data.result[0].number)
                    .then((response) => {
                      _this.mergeUrl = response.data.output_files[0];
                      _this.rotateFunction()
                    })
                    .catch((response) => {
                    });
                  resolve(res)
                }).catch((err) => {
                  //console.log("err:", err);
                  if (times > 0) {
                    setTimeout(() => {
                      reMergeTry(times - 1, api, url, num, _this)
                    })
                  } else {
                    _this.$message({
                      showClose: true,
                      duration: 8000,
                      type: "error",
                      message: "The network is abnormal. Please check the network connection.",
                    });
                    _this.$gtag.event("process_fail", {
                      event_category: _this.pageName,
                      event_label: "network_error",
                    });
                    _this.$gtag.event("process_file", {
                      event_category: _this.pageName,
                      event_label: "fail",
                    });
                    let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
                    _this.$gtag.event("process_fail", {
                      event_category: _this.pageName,
                      event_label: covertTime,
                    });
                    //console.log("失败");
                    _this.$store.commit("changeAppState", "failConvert");
                    //console.log("失败:", _this.$store.state.appState);
                    reject(err)
                  }
                })
            }
            reMergeTry(times, api, url, num, _this)
          })

        })
        reMergeTry(3, "/app/pdfmerge", formData, 0, _this);
      },

      //旋转页面
      rotateFunction() {
        this.splitPageAfter = [
          {
            angle: "90",
            pages: "",
          },
          {
            angle: "180",
            pages: "",
          },
          {
            angle: "270",
            pages: "",
          },
        ];
        let _this = this;
        this.processSplitPage();
        if (this.splitPageAfter.length == 0 && this.checkAppState() != "start") {
          // _this.mergeUrl = _this.fileList[0].fileUrl;
          _this.downInof.downUrl = _this.mergeUrl;
          _this.$store.commit("changeAppState", "converted");
          _this.$gtag.event("click_combine", {
            event_category: _this.pageName,
            event_label: "no"
          });
          let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
          _this.$gtag.event("process_success", {
            event_category: _this.pageName,
            event_label: `${covertTime}-${(_this.fileSize / 1024 / 1024).toFixed(3)}`
          });
          _this.$gtag.event("process_file", {
            event_category: _this.pageName,
            event_label: "success",
          });
          _this.$gtag.event("process_success", {
            event_category: _this.pageName,
            event_label: _this.fileList.length
          });
        } else {
          _this.$gtag.event("click_combine", {
            event_category: _this.pageName,
            event_label: "use_rotate"
          });
          let reRotateTry = ((times, api, url, num, _this) => {
            //console.log("reRotateTry:", times, api, url, num, _this);
            //console.log("reRotateTry:", url);
            return new Promise((resolve, reject) => {
              function reRotateTry(times, api, url, num, _this) {
                // //console.log(`第${times}次重试`);
                _this.$http
                  .postRotate("/app/pdfrotate", {
                    token: _this.$store.state.pdfToken,
                    rotation: _this.splitPageAfter,
                    file_url: _this.mergeUrl,
                  }).then((res) => {
                    ////console.log(response);
                    if (_this.$store.state.appState == 'converting') {
                      _this.downInof.downUrl = res.data.result[0];
                      _this.$store.commit("changeAppState", "converted");
                    }
                    let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
                    _this.$gtag.event("process_success", {
                      event_category: _this.pageName,
                      event_label: `${covertTime}-${(_this.fileSize / 1024 / 1024).toFixed(3)}`
                    });
                    _this.$gtag.event("process_file", {
                      event_category: _this.pageName,
                      event_label: "success",
                    });
                    _this.$gtag.event("process_success", {
                      event_category: _this.pageName,
                      event_label: _this.fileList.length
                    });
                    resolve(res)
                  }).catch((err) => {
                    //console.log("err:", err);
                    if (times > 0) {
                      setTimeout(() => {
                        reRotateTry(times - 1, api, url, num, _this)
                      })
                    } else {
                      _this.$gtag.event("process_fail", {
                        event_category: _this.pageName,
                        event_label: "network_error",
                      });
                      let covertTime = ((new Date().getTime() - _this.nowTime) / 1000).toFixed(1)
                      _this.$gtag.event("process_fail", {
                        event_category: _this.pageName,
                        event_label: covertTime,
                      });
                      _this.$gtag.event("process_file", {
                        event_category: _this.pageName,
                        event_label: 'fail',
                      });
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: "The network is abnormal. Please check the network connection.",
                      });
                      _this.$store.commit("changeAppState", "failConvert");
                      ////console.log(response);
                      // _this.openPop(_this, 5, "convert");
                      reject(err)
                    }
                  })
              }
              reRotateTry(times, api, url, num, _this)
            })

          })
          reRotateTry(3, "/app/pdfrotate", _this.splitPageAfter, _this.mergeUrl, _this);
        }
      },
      processSplitPage() {
        let degArr1 = [];
        let degArr2 = [];
        let degArr3 = [];
        this.splitPage.forEach(function (index, pageNum) {
          // //console.log("index, pageNum:", index, pageNum);
          // //console.log("index, pageNum:", typeof index, typeof pageNum);
          // //console.log("index", index % 360);

          if (index % 360 == 90) {
            // //console.log("90:", 1111);
            degArr1.push(pageNum + 1);
          } else if (index % 360 == 180) {
            // //console.log("180:", 1111);
            degArr2.push(pageNum + 1);
          } else if (index % 360 == 270) {
            degArr3.push(pageNum + 1);
          }
        });

        if (degArr3.length != 0) {
          this.splitPageAfter[2].pages = degArr3.join(",");
        } else {
          this.splitPageAfter.splice(2, 1);
        }
        if (degArr2.length != 0) {
          this.splitPageAfter[1].pages = degArr2.join(",");
        } else {
          this.splitPageAfter.splice(1, 1);
        }
        if (degArr1.length != 0) {
          this.splitPageAfter[0].pages = degArr1.join(",");
        } else {
          this.splitPageAfter.splice(0, 1);
        }
      },
      async loadFile(url, num) {
        const _this = this;
        let numPage = num
        await PDF.getDocument(url)
          .then((pdf) => {
            this.pdfDoc = pdf;
            this.pages = this.pdfDoc.numPages;
            _this.fileList[num]["pageNums"] = this.pages
            _this.fileList[num]["rotate"] = 0
            _this.fileList[num]["pdf"] = this.pdfDoc
            this.$nextTick(() => {
              this.renderPage(1, numPage);
            });
          })
          .catch(function (err) {
            _this.$nextTick(() => {
              //console.log("损坏文件:", _this.fileList[num].fileUrl);
              _this.badFile.push(num)
              _this.fileList[num].fail = true
              console.log("loadFile失败");
              //console.log(" _this.fileListLoadFile:", _this.fileList);
              _this.fileList[num].preImg = require('../assets/merge/failfiles.svg')
              _this.fileList.length == 0 ? _this.$store.commit("changeAppState", "start") : "";
              //console.log(err);
            })

            return false;
          })

      },
      /*
       * 渲染PDF文件
       * */
      renderPage(num, index) {
        const _this = this;
        // this.fileList[index]["pdf"].getPage(num).then((page) => {
        this.pdfDoc.getPage(num).then((page) => {
          // const __this = this;
          let indexId = index + 1
          //console.log("indexId:", indexId);
          let canvas = document.getElementById("canvas" + indexId);
          //console.log("canvas:", canvas);
          let ctx = canvas.getContext("2d");
          let dpr = 1; // 设备像素比
          let bsr =
            ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio ||
            1; // 浏览器在渲染canvas之前会用几个像素来来存储画布信息,类似img
          let ratio = dpr / bsr;
          // let viewport = page.getViewport(0.8);
          let viewport = page.getViewport(1.5);
          //console.log("viewport:", viewport, ratio);
          canvas.width = viewport.width * ratio; // 画布大小,默认值是width:300px,height:150px
          canvas.height = viewport.height * ratio;
          canvas.style.width = viewport.width + "px"; // 画布的框架大小
          canvas.style.height = viewport.height + "px";
          let renderContext = {
            canvasContext: ctx,
            viewport: viewport,
          };
          // //console.log(renderContext.toDataURL('image/jpg'));
          this.canvasList.push(renderContext);
          var renderTask = page.render(renderContext);
          renderTask.promise
            .then(function () {
              // setTimeout(() => {
              _this.$nextTick(() => {
                _this.occupancyFlag = false;
                // console.log(document.querySelectorAll(".pdf-container canvas")[indexId - 1].toDataURL("image/jpg"));
                _this.fileList[indexId - 1].preImg = document.querySelectorAll(".pdf-container canvas")[indexId - 1].toDataURL("image/jpg");
              })
            })
            .catch(function (err) {
              console.log("renderPage失败");
              _this.occupancyFlag = false;
              // _this.fileList[indexId - 1].preImg = document.querySelectorAll(".pdf-container canvas")[indexId - 1].toDataURL("image/jpg")
              _this.fileList[indexId - 1].preImg = require('../assets/merge/failfiles.svg')

            })
        });
      },

      //旋转pdf
      turnRight(num) {
        //console.log(num);
        if (typeof num == "number") {
          this.fileList[num]["rotate"] += 90
          if (this.fileList[num]["rotate"] >= 360) {
            this.fileList[num]["rotate"] = 0
          }
          document.querySelectorAll(".pre-bg")[num].style.transform =
            "rotate(" + +this.fileList[num]["rotate"] + "deg)";
        }
      },
      //重试
      reTryUpload(times, api, url, num, _this) {
        let gaStartime = new Date().getTime()
        return new Promise((resolve, reject) => {
          function reTryFunc(times, api, url, num, _this) {
            // //console.log(`第${times}次重试`);
            _this.$http
              .post(api, {
                token: _this.$store.state.pdfToken,
                'files[]': url,
                task_id: _this.taskId
              }).then((res) => {
                // //console.log("res:", res);
                // //console.log(res.code == 200);
                if (res.code == 200) {
                  _this.failArray.push(1)
                  // if (!_this.fileList[num].raw) _this.fileList.splice(num, 1)
                  // let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                  let reader = new FileReader();
                  reader.readAsDataURL(_this.fileList[num].raw);
                  //console.log("reTRYnum:", num);
                  reader.onload = () => {
                    _this.loadFile(reader.result, num);
                  };
                  reader.onerror = (error) => {
                    //console.log("加载出错:", error);
                  };
                  _this.fileType = _this.fileList[0].name.substr(
                    _this.fileList[0].name.lastIndexOf(".") + 1
                  );
                  _this.clickFlag = true;
                  _this.uploadState = "1";
                  // _this.btnloading = false
                  _this.fileList.length > 0 ? (_this.fileList[num]["fileUrl"] = res.data.response_file[0]) : null;
                } else {
                  _this.uploadState = '0'
                }
                resolve(res)
              }).catch((err) => {
                //console.log("err:", err);
                if (times > 0) {
                  setTimeout(() => {
                    reTryFunc(times - 1, api, url, num, _this)
                  })
                } else {
                  _this.uploadState = '0'
                  // _this.btnloading = false
                  // _this.$store.commit("changeAppState", "failUpload");
                  _this.failArray.push(2)
                  //console.log("_this.failArray:", _this.failArray.length, _this.filelen);
                  reject(err)
                }
              }).finally(() => {
                //console.log("finally:", _this.failArray.length, _this.filelen);
                //当请求次数和文件数量相同时在进行操作
                if (_this.failArray.length == _this.filelen) {
                  setTimeout(() => {
                    _this.canClick = true
                    let fail = _this.failArray.filter((item) => item == 2) //失败的数量
                    let successArray = _this.failArray.filter((item) => item == 1) //成功的数量
                    let fail111 = _this.fileList.filter((item) => item.fail == true) //所有的损坏文件
                    let failLength = fail111.length - _this.failJPG //总共的损坏文件删掉之前的破损文件
                    _this.failJPG = fail111.length
                    let num = _this.failArray
                    console.log("fail:", fail);
                    let len1 = _this.fileList.length
                    let len2 = _this.failArray.length
                    let len3 = len1 - len2 //原先数组的长度
                    let size = 0
                    //console.log("len1:", len1, len2, len3);
                    //统计此次上传文件的大小
                    _this.failArray.forEach((item, index) => {
                      //console.log("item:", _this.fileList[index + len3]);
                      if (_this.fileList[index + len3]) {
                        //console.log("size:", _this.fileList[len3 + index].size);
                        size += _this.fileList[len3 + index].size
                      }
                      if (item == 2) {
                        _this.fileList.forEach((item, index) => {
                          _this.fileList.splice(len3 + index, 1)
                        })
                      }
                    })
                    //如果失败的长度和文件上传的长度一致则弹窗，部分成功不弹窗，破损文件不算上传失败
                    if (fail.length == _this.filelen) {
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: "The network is abnormal. Please check the network connection.",
                      })
                      _this.$gtag.event("upload_fail", {
                        event_category: _this.pageName,
                        event_label: "network_error",
                      });
                      _this.$gtag.event("upload_file", {
                        event_category: _this.pageName,
                        event_label: "fail",
                      });
                      _this.$gtag.event("upload_fail", {
                        event_category: _this.pageName,
                        event_label: "0.1",
                      });
                    } else {
                      //成功或者部分成功
                      if (successArray.length != _this.filelen) {
                        console.log("成功或者部分成功");
                        _this.$message({
                          showClose: true,
                          duration: 8000,
                          type: "error",
                          message: _this.$t(`message.UploadFun.message[3]`),
                        })
                      }
                      let updateTime = ((new Date().getTime() - gaStartime) / 1000).toFixed(1)
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: "null",
                      });
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: `${updateTime}-${(size / 1024 / 1024).toFixed(3)}`,
                      });
                      _this.$gtag.event("upload_file", {
                        event_category: _this.pageName,
                        event_label: "success",
                      });
                      _this.$gtag.event("upload_size", {
                        event_category: _this.pageName,
                        event_label: (size / 1024 / 1024).toFixed(3)
                      });
                      _this.$gtag.event("upload_success", {
                        event_category: _this.pageName,
                        event_label: `${_this.filelen}-${failLength}`,
                      });
                    }
                    //破损文件长度大于0
                    // if (_this.badFile.length > 0) {
                    if (_this.badFile.length == _this.filelen) {
                      _this.$message({
                        showClose: true,
                        duration: 8000,
                        type: "error",
                        message: _this.$t(`message.UploadFun.message[5]`),
                      })

                    }
                    // _this.failArray = []
                    //console.log("进来了：", _this.fileList);
                    if (_this.fileList.length == 0) {
                      _this.$store.commit("changeAppState", "failUpload");
                    }
                    _this.failArray = []
                    _this.badFile = []
                    _this.filelen = 0
                  }, 1000)
                  // _this.btnloading = false

                }
              })
          }
          reTryFunc(times, api, url, num, _this)
        })
      },

      onStart(e) {
        //console.log("es:", e);
        this.drag = true
      },
      onEnd(e) {
        console.log("ee:", e);
        this.drag = false
        let a = this.fileList[e.oldIndex].rotate
        let b = this.fileList[e.newIndex].rotate
        console.log("a,b:", a, b);
        console.log("a,b:", this.fileList[e.oldIndex], this.fileList[e.newIndex]);
        console.log(document.querySelectorAll(".pre-bg"));
        document.querySelectorAll(".pre-bg").forEach((e, index) => {
          console.log("-----------:", e);
          console.log("-----------:", this.fileList[index]);
          console.log("-----------:", this.fileList[index]["rotate"]);
          if (this.fileList[index]["rotate"] == undefined || this.fileList[index]["rotate"] == 0) {
            console.log("undefined111");
            e.style.transform = "rotate(0deg)"
            return
          } else {
            console.log("！=0");
            e.style.transform = "rotate(" + this.fileList[index]["rotate"] + "deg)"
          }
        })
      },
    },
    watch: {
      clickCovertFlag: {
        handler: function () {
          this.uploadState == 1 ? this.convertFun() : "";
          this.uploadState == 0 ? this.openPop(this, 5) : "";
        },
      },
      uploadState: {
        handler: function (value) {
          value == 1 && this.clickCovertFlag ? this.convertFun() : "";
          value == 0 && this.clickCovertFlag ? this.openPop(this, 5) : "";
        },
      },
      $route: {
        handler(to, from) {
          //console.log("merge路由变了哦:", to, from);
        },
        deep: true
      },
      fileList: {
        handler(val) {
          const _this = this
          if (val.length > 0) {
            this.downInof.downName = val[0].name;
          } else {
            _this.clickUploadFlag = false

          }
          if (val.length > 1) {
            this.downInof.downName = val[0].name;
            // this.canClick = true;
            // console.log("canClick");
          }
          // else {
          //   this.canClick = false;

          // }
          this.$store.commit("changeAppFileList", val);
        },
        deep: true, //true 深度监听
      },
    },
    mounted() {
      this.$store.commit('changeAppState', 'start')
      this.$store.commit("changeFileType", 'pdf');
      this.operation = sessionStorage.getItem('getOS');
      this.$http
        .get("/app/user/get-taskid").then((res) => {
          this.taskId = res.data.task_id
          console.log("res.data:", res.data);
        })
      // if (this.$store.state.appFileList.length > 0) {
      //   this.getFileFromState = true;
      //   this.clickUpload(this.$store.state.appFileList[0]);
      // }
      this.$store.commit("changeStateFunName", "merge");
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep .el-upload {
    &:focus {
      color: black;
      /* border-color: red */
    }
  }

  .btn-combine {
    &:hover~.hover-span {
      display: block;
    }
  }

  .pre-bg {
    width: 180px;
    height: 234px;
    position: absolute;
    top: 12px;
    left: 30px;
    background: url("../assets/merge/filebg.svg") no-repeat;
  }

  .hover-span {
    position: relative;
    display: none;
    top: 445px;
    /* width: 285px; */
    height: 24px;
    left: 220px;
    padding: 5px 8px;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #FFFFFF;
  }

  .tip {
    position: relative;
  }

  .tip:hover:after {
    position: absolute;
    top: 20px;
    left: 0;
    content: attr(data-title);
    /* //在这里设内置好title出现的位容置就好了 */
    color: #000000;
    border: 1px solid #242424;
    border-radius: 5px;
    background-color: #E5E5E5;
  }

  .uploadBox {
    /* text-align: center; */
    margin: 6px 8px;
    width: 110px;

    ::v-deep .el-upload,
    ::v-deep .el-upload-dragger {
      /* width: 100%; */
      width: 110px !important;
      height: 28px;

    }
  }

  .dotteBox {
    padding: 24px;
  }

  .fun-area-wrap {
    height: 548px;
    background: #fff;
    /* border-radius: 16px; */
  }


  .border-left {
    border-radius: 16px 0 0 16px;
  }

  .border-right {
    border-radius: 0 16px 16px 0;
  }

  .hover-area {
    background: rgba(27, 33, 39, 0.3);
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 35px;
    padding: 2px 6px;
    height: 32px;
    width: 45px;
    background: rgba(27, 33, 39, 0.5);
    border-radius: 16px;

    span:hover {
      cursor: pointer;
      color: #DBEAFD !important;

    }

    .turn-right {
      margin: 0 12px;
      position: relative;
      opacity: 0.6;
    }
  }

  .hover-operation-area {
    background: rgba(27, 33, 39, 0.3);
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: 35px;
    padding: 2px 6px;
    height: 32px;
    width: 100px;
    background: rgba(27, 33, 39, 0.5);
    border-radius: 16px;
    z-index: 5;

    span:hover {
      cursor: pointer;
      color: #DBEAFD !important;

    }

    .turn-right {
      margin: 0 12px;
      position: relative;
      opacity: 0.6;
    }
  }

  .item-cell {
    text-align: center;
    position: relative;

    .hover-operation-area {
      opacity: 0;
    }

    .hover-area {
      opacity: 0;
    }

    &:hover {
      background: #f6f6f6;

      .hover-operation-area {
        opacity: 1;
      }

      .hover-area {
        opacity: 1;
      }

      ~#file-name-hover {
        height: auto;
        padding: 32px 0;
        border-top: 1px solid #ccc;
        background: red;
      }
    }

    &:hover {
      background: #f6f6f6;
    }

    .pre-img-cell-wrap {
      height: 212px;
      width: 232px;
      text-align: center;

      .pre-num {
        width: 25px;
        height: 25px;
        border-radius: 50px;
        color: white;
        position: relative;
        left: 28px;
        top: 10px;
        z-index: 10;
        background: rgba(29, 29, 31, 0.3);
      }

      .pre-img-cell {
        width: 150px;
        position: relative;
        display: block;
        /* z-index: 5; */

      }
    }

    .turn-right {
      margin-left: 12px;

      &:hover {
        cursor: pointer;
        opacity: 1;
      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.notClick {
        opacity: 0.4;
      }
    }

    .turn-left {
      opacity: 0.6;

      &:hover {
        cursor: pointer;
        opacity: 1;

      }

      &:active {
        transform: translate(1px, 1px);
      }

      &.notClick {
        opacity: 0.4;
      }
    }
  }

  .pre-img-cell {
    .pre-icon {
      max-width: 212px;
      max-height: 212px;
      width: auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .add-img-wrap {
    color: #1D1D1F;

    h3 {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      text-align: center;

      color: #1D1D1F;
    }

    .prompt-tit {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-align: center;
      color: rgba(29, 29, 31, 0.5);
      margin-top: 16px;
    }
  }




  .fun-area-wrap-border {
    border-radius: 16px;
  }

  .fun-area-wrap .pre-icon-wrap {
    width: 100%;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    padding: 32px 2px 32px 40px;

    .pre-icon-img-wrap {
      height: 212px;
    }

    .item-cell {
      display: inline-block;
      width: 232px;
      height: 276px;
      margin: 0 29px 32px 0;

      img {
        max-width: 232px;
        max-height: 213px;
      }
    }

    .pre-img-cell-conner {
      /* position: relative; */
      width: fit-content;
      margin: 0 auto;
      /* box-shadow: 0px 4px 12px rgb(33 34 42 / 10%); */
      position: absolute;
      top: 7px;
      left: 12px;
    }

    .pre-img-bg {
      position: absolute;
      width: 45px;
      height: 45px;
      top: 0;
      right: 0;
    }

  }

  .action-area-wrap {
    padding: 24px 32px;
  }


  #pre-pdf-area {
    display: none;
  }

  .file-name-wrap {
    display: flex;
    max-width: 150px;
    margin: 40px auto;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: rgba(29, 29, 31, 0.7);

    &:hover {
      ~.hover-tit {
        display: block;
      }
    }
  }

  .grid-content {
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-color: #f5f5f5 #fff;
    scrollbar-width: thin;

    &:hover::-webkit-scrollbar {
      /* display: block */
    }

    &::-webkit-scrollbar {
      width: 6px;
      height: 16px;
      background-color: #f5f5f5;
      /* display: none; */
    }

    /*定义滚动条轨道
 内阴影+圆角*/
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: #fff;
    }

    /*定义滑块
 内阴影+圆角*/
    &::-webkit-scrollbar-thumb {
      border-radius: 12px;
      background-color: rgba(0, 22, 56, 0.1);
    }

  }


  @keyframes rotate {
    from {
      transform: rotate(-90deg);

    }

    to {
      transform: rotate(270deg);
    }
  }
</style>